<template>
  <div>
    <b-card-group>
      <b-card border-variant="dark" title="Quick Stats - by Month"  class="mb-3 mr-1">
        <div>
          <b-card-text>
            Prod. Volume {{ quickStats }} <a href="#" class="card-link">(Open Chart)</a>
          </b-card-text>
          <b-card-text>
            Order Volume {{ quickStats }} <a href="#" class="card-link">(Open Chart)</a>
          </b-card-text>
        </div>
      </b-card>

      <b-card border-variant="dark" title="OEE" class="mb-3 text-center">
        <div>
          <b-card-group deck>
            <b-card class="text-center" bg-variant="success" text-variant="white" header="Availlability">
              <b-card-text ></b-card-text>
            </b-card>
          
            <b-card bg-variant="primary" text-variant="white" header="Performance" >
              <b-card-text></b-card-text>
            </b-card>
          
            <b-card bg-variant="warning" text-variant="white" header="Success">
              <b-card-text></b-card-text>
            </b-card>

            <b-card bg-variant="danger" text-variant="white" header="OEE">
              <b-card-text></b-card-text>
            </b-card>
          </b-card-group>
        </div>
      </b-card>
    </b-card-group>

    <b-card-group>
      <b-card border-variant="dark" class="mb-3 mr-1 text-center">
          <div>
            <b-card-title class="text-dark" style="font-size: x-large">Top 5 Machine Production (%)</b-card-title>
            <b-row>   
              <b-col cols="12" sm="4" class="my-1"
                v-for="(data, index) in topProduct" :key="data">

                <b-card class="border border-dark" v-if=" index % 2 === 0" bg-variant="warning">
                  <b-card-title  class="text-white">{{ data.itemName }}</b-card-title>
                  <b-card-text  class="text-white" style="font-size: large;" >{{ data.quantity }} m</b-card-text>
                </b-card>
                <b-card class="border border-dark" v-else bg-variant="success">
                  <b-card-title  class="text-white">{{ data.itemName }}</b-card-title>
                  <b-card-text  class="text-white" style="font-size: large;" >{{ data.quantity }} m</b-card-text>
                </b-card>
              
              </b-col>

            </b-row>
          </div>
        </b-card>
      <b-card border-variant="dark" title="Average Process Time (By Order)" class="mb-3 text-center">
          <b-card-text>
          </b-card-text>
        </b-card>
    </b-card-group>

    <div class="pt-2">
      <b-card border-variant="dark" title="">
        <div class="pt-2">
          <b-row>
            <b-col cols="12">
                <div style="overflow-x: visible;">
                    <b-table
                        hover
                        responsive
                        small
                        head-variant="dark"
                        outlined
                        :items="actionable"
                        :fields="headers"
                        :sort-by.sync="sortBy"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :stacked= stackedStatus               
                    >
                    </b-table>
                </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
          </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { dateFormat, userAccess, viewAccess } from '@/utils/utils.js';

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      pageOptions: [5, 10, 20, 100],
      giveTime : false,
      stackedStatus:"md",
      optionsrole: {
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        colors:['#662E9B'],
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
          position: 'bottom'
        },
      },
      headers: 
        [
          { key: 'orderNumber', label: 'Job Order Number' },
          { key: 'props.type', label: 'Process'},
          { key: 'actions', label: 'Machines'},
          { key: 'createdAtFormatted', label: 'Times' },
        ],

      optionsuser: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
      perPage: 5,
      currentPage: 1,
      sortBy: "",
    }
  },
  methods: {
  },
  computed: {
    quickStats(){
        return this.$store.getters["dashboard/getManufactureQuickStats"]
      },

    topProduct(){
      return this.$store.getters["dashboard/getManufactureTopProduction"]
    },

    actionable(){
      let data = this.$store.getters["dashboard/getManufactureActionable"]

      let getAllData = data.map(el=>{
        return {
          ...el,
          createdAtFormatted: dateFormat(el.createdAt),
        }
      })
      
      return getAllData
    },
    rows() {
      return this.actionable.length;
    },
    
  },
  created() {
    document.title = 'Dashboard | Tai Sin'
  },
  mounted() {
    this.$store.dispatch("dashboard/fetchManufactureQuickStats")

    this.$store.dispatch("dashboard/fetchManufactureOEE")

    this.$store.dispatch("dashboard/fetchManufactureTopProduction")

    this.$store.dispatch("dashboard/fetchManufactureAverage")

    this.$store.dispatch("dashboard/fetchManufactureActionable")
    
  },
}
</script>